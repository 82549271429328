import { ref, computed, onMounted } from 'vue';
import { useResizeObserver, useWindowSize } from './observer';

export function toVisibleProgress(swiper, progress) {
  if (!swiper) return 0;
  const visiblePortion = swiper.width;
  const fullWidth = swiper.wrapperEl?.scrollWidth;
  const rest = fullWidth - visiblePortion;
  return ((progress * rest) + visiblePortion) / fullWidth;
}

export function useVisibleProgress() {
  const start = ref(0);
  const end = ref(0);
  const onProgress = (swiper, p) => {
    const fullWidth = swiper?.wrapperEl?.scrollWidth;
    const visiblePortion = swiper?.width;
    start.value = p - ((visiblePortion * p) / fullWidth);
    end.value = start.value + (visiblePortion / fullWidth);
  };
  return { onProgress, start, end };
}

export function useSwiperBounds() {
  const state = ref('start');
  const progress = ref(0);

  const onProgress = (_, swiperProgress) => {
    progress.value = swiperProgress;
    if (swiperProgress <= 0) state.value = 'start';
    else if (swiperProgress >= 1) state.value = 'end';
    else state.value = 'between';
  };

  return { state, progress, onProgress };
}

export function useSwiperState() {
  const slideRef = ref(null);
  const fullVisible = ref(false);
  const { state: swiperProgressState, onProgress: onSwiperProgress, progress } = useSwiperBounds();

  const wrapperRef = useResizeObserver((entry) => {
    if (!slideRef.value) return;
    const slideRect = slideRef.value?.$el?.getBoundingClientRect();
    fullVisible.value = entry.contentRect.width >= slideRect.width;
  });

  const state = computed(() => (fullVisible.value ? 'full' : swiperProgressState.value));

  return {
    wrapperRef,
    slideRef,
    onSwiperProgress,
    state,
    progress,
  };
}

export function useCurrentSlide() {
  const slide = ref(1);
  const onSlideChange = (swiper) => {
    slide.value = swiper.activeIndex;
  };
  return { onSlideChange, slide };
}

export function usePage() {
  const page = ref(1);
  const pages = ref(1);
  const updatePage = (swiper) => {
    page.value = Math.ceil(
      swiper.activeIndex
      / swiper.params.slidesPerGroup,
    ) + 1;
    pages.value = Math.ceil(
      swiper.slides.length
      / swiper.params.slidesPerGroup,
    );

    if (swiper.progress === 1) {
      page.value = pages.value;
    }
  };
  return { updatePage, page, pages };
}
