<template>
  <section class="header part" :class="[ payload.optionen ]">
    <div v-view class="constrain content">
      <div class="title">
        <h1 v-view v-text-split="payload.titeltext" class="animate stagger" />
        <h3
          v-if="payload.untertitel?.length"
          v-view
          v-text-split="payload.untertitel"
          class="animate stagger"
          :style="cssVars({ delayOffset: `${splitText(payload.titeltext).length * 0.2}s` })"
        />
      </div>
      <box
        class="overflow-container left"
        force-aspect-ratio
        :aspect="1.466"
      >
        <box
          force-aspect-ratio
          :aspect="1.238"
        >
          <div class="animate reveal">
            <Image class="image image-left" :src="imageLeft" />
          </div>
        </box>
      </box>
      <div class="overflow-container right">
        <box
          class="spacer"
          no-padding
          force-aspect-ratio
          :aspect="0.7714"
        >
          <box
            force-aspect-ratio
            no-padding
            :aspect="0.7714"
          >
            <div class="animate reveal delayed">
              <Image class="image image-right" :src="imageRight" />
            </div>
          </box>
        </box>
      </div>
    </div>
    <div class="constrain slider-content">
      <div class="title">
        <h1>{{ payload.titeltext }}</h1>
        <h3 v-if="payload.untertitel?.length">
          {{ payload.untertitel }}
        </h3>
      </div>
      <slider
        ref="sliderRef"
        v-slot="{ item }"
        class="slider"
        :items="payload.bilder"
        :slides-per-view="{ mobile: 1.6 }"
        :slides-per-group="{ mobile: 1.6 }"
        @progress="onProgress"
        @afterInit="updatePage"
        @slideChange="updatePage"
        @resize="updatePage"
      >
        <Image class="slider-image" :src="item" />
      </slider>
      <page-progress
        class="progress"
        :start="start"
        :end="end"
        :current-page="page"
        :pages="pages"
        @prev="prev"
        @next="next"
      />
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useVisibleProgress, usePage } from '@/composables/swiper';
import { splitText } from '@/utils/textSplit';
import { cssVars } from '@/utils/css';

export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const sliderRef = ref(null);

    const { onProgress: visibleProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const onProgress = (swiper, progress) => {
      visibleProgress(swiper, progress);
      updatePage(swiper);
    };

    return {
      sliderRef,
      page,
      pages,
      start,
      end,
      prev,
      next,
      onProgress,
      updatePage,
      splitText,
      cssVars,
      imageLeft: computed(() => props.payload?.bilder[0]),
      imageRight: computed(() => props.payload?.bilder[1]),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.header.part .content {
  width: 100%;
  display: grid;
  grid-template-areas:
    "l t"
    "l r";
  grid-template-rows: auto auto;
  grid-template-columns: 5fr 6fr;
  grid-column-gap: 7%;

  @include responsive('grid-row-gap', 0, 5%);
  @include responsive('padding-bottom', 3rem, 0);

  h1 {
    margin-bottom: px(23);
  }
  h3 {
    margin-bottom: 0;
  }

  .title {
    grid-area: t;
    display: none;

    @screen sm {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }

  .overflow-container {
    position: relative;

    :deep(img) {
      @apply object-cover;
      height: 100%;
    }

    &.left {
      grid-area: l;
      min-width: px(184);

      .reveal {
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
        height: 100%
      }

      :deep(img) {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &.right {
      grid-area: r;
      align-self: end;

      .spacer {
        @include responsive('margin-top', 3rem, 0);

        width: 100%;
        min-height: px(162);
        overflow: visible;

        > :deep(.content) {
          @apply absolute;
          min-width: px(210);
          height: 100%;
          right: 0;
          bottom: 0;
          top: auto;
          left: auto;
        }
      }

      .reveal {
        height: 100%;
      }

      :deep(img) {
        object-fit: cover;
      }
    }
  }
}

.header .slider-content {
  display: none;
}

.header.sliderOnMobile {
  .content {
    @include responsive('display', none, grid);
  }
  .slider-content {
    @include responsive('display', block, none);
  }
}

.slider-content {
  h1 {
    margin-bottom: px(5);
  }

  h3 {
    margin-bottom: px(23);
  }

  .slider {
    margin-bottom: px(10);
    overflow: visible;
  }

  .slider-image {
    @apply rounded-big;
    object-fit: cover;
  }
}

.reveal {
  position: relative;

  width: 100%;
  overflow: hidden;

  @apply rounded-small;
  @screen md {
    @apply rounded-big;
  }
}
</style>
